<!--
 * @Description: 
 * @Author: wendan
 * @Date: 2022-03-04 19:08:29
 * @LastEditTime: 2022-03-09 10:05:02
 * @LastEditors: wendan
 * @Reference: 
-->
<template>
  <div class="wrapper">
    <div class="d-tabs">
      <el-row>
        <el-col :span="4" class="tab-wrap">
          <div class="tab">
            <p>总接通人数</p>
            <p></p>
            <p>{{ report.all.personCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab">
            <p>总呼出次数</p>
            <p></p>
            <p>{{ report.all.outCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab">
            <p>总呼入次数</p>
            <p></p>
            <p>{{ report.all.inCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab">
            <p>总通话时长</p>
            <p></p>
            <p>{{ report.all.totalTimes }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab">
            <p>总未接通人数</p>
            <p></p>
            <p>{{ report.all.notAnsPersonCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab">
            <p>总未接通次数</p>
            <p></p>
            <p>{{ report.all.notAnsCnt }}</p>
            <p></p>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4" class="tab-wrap">
          <div class="tab2">
            <p>本月总接通人数</p>
            <p></p>
            <p>{{ report.month.personCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab2">
            <p>本月总呼出次数</p>
            <p></p>
            <p>{{ report.month.outCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab2">
            <p>本月总呼入次数</p>
            <p></p>
            <p>{{ report.month.inCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab2">
            <p>本月总通话时长</p>
            <p></p>
            <p>{{ report.month.totalTimes }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab2">
            <p>本月总未接通人数</p>
            <p></p>
            <p>{{ report.month.notAnsPersonCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab2">
            <p>本月总未接通次数</p>
            <p></p>
            <p>{{ report.month.notAnsCnt }}</p>
            <p></p>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4" class="tab-wrap">
          <div class="tab3">
            <p>上月总接通人数</p>
            <p></p>
            <p>{{ report.prevoisMonth.personCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab3">
            <p>上月总呼出次数</p>
            <p></p>
            <p>{{ report.prevoisMonth.outCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab3">
            <p>上月总呼入次数</p>
            <p></p>
            <p>{{ report.prevoisMonth.inCallCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab3">
            <p>上月总通话时长</p>
            <p></p>
            <p>{{ report.prevoisMonth.totalTimes }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab3">
            <p>上月总未接通人数</p>
            <p></p>
            <p>{{ report.prevoisMonth.notAnsPersonCnt }}</p>
            <p></p>
          </div>
        </el-col>
        <el-col :span="4" class="tab-wrap">
          <div class="tab3">
            <p>上月总未接通次数</p>
            <p></p>
            <p>{{ report.prevoisMonth.notAnsCnt }}</p>
            <p></p>
          </div>
        </el-col>
      </el-row>

 
    </div>
    <div class="con-form">
      <el-form ref="form" :model="form" label-width="150px">
      </el-form>
    </div>


  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      report: {
        all: {},
        month: {},
        prevoisMonth: {},
      },
    }
  },
  computed: {
    ...mapState(['layouts']),
  },
   created() {
			this.initView()
		},
  methods: {
     ...mapActions([
				"getCallTableList"
			]),
      initView() {
				this.getLists();
			},
			getLists() {
         this.loading = true;  
      this.getCallTableList({
      }).then(res => {
        if (res.code == 200) {
          this.report = res.data;
        }
        this.loading = false;
      })
			},

  },
}
</script>

<style lang="scss" scoped>
.d-tabs,.con-form {
  width: 100%;
}
.btn {
  padding: 20px;
}
.tab-wrap {
        padding: 20px;
  .tab {
          background-color: #6868f1;
    line-height: 70px;
    margin: 0 auto;
    width: 200px;
    border-radius: 30px;
    text-align: center;
    color: #fff;
  }
  .tab2 {
    background-color: #33ca77;
    line-height: 70px;
    margin: 0 auto;
    width: 200px;
    border-radius: 30px;
    text-align: center;
    color: #fff;
  }
  .tab3 {
    background-color: #0e0e91;
    line-height: 70px;
    margin: 0 auto;
    width: 200px;
    border-radius: 30px;
    text-align: center;
    color: #fff;
  }
}
</style>